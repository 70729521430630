import React from 'react';
import PropTypes from 'prop-types';
import locationHOC from '../../locationProvider/locationHOC';
import {navigateToParametrized, getQueryParam} from '../../../lib/url';
import {QUERY_PARAMS, ROUTES} from '../../../constants/navigation';
import generalFailedPC from '../../fetch/generalFailedPC';
import {createFetchQuotationMakeOrder} from '../../../backend/apiCalls';
import {Trans, t} from '@lingui/macro';
import ButtonFetch from '../../fetch/ButtonFetch';
import { CartOrderFormValues, Location, QuotationDetail } from '../../../constants/propTypesDefinitions';
import withSessionHOC from '../../sessionProvider/withSessionHOC';
import { SESSION_ATTRIBUTES } from '../../sessionProvider/SessionProvider';
import { RIGHTS } from '../../../constants/Rights';
import { TRANSPORT_TYPE } from '../../../constants/transportTypes';
import { isAddressRequired } from '../../../lib/project';
/**
 * @fero
 */


class QuotationMakeOrder extends React.PureComponent {
    static propTypes = {
        location: Location.isRequired,
        quotationDetails: QuotationDetail.isRequired,
        params: CartOrderFormValues.isRequired,
    };

    orderAttributes = () => {
        const {location, quotationDetails, params} = this.props;
        const quotationOrderItemsParam = getQueryParam(location, QUERY_PARAMS.QUOTATION_ORDER_ITEMS);
        const quotationOrderItems = quotationOrderItemsParam != null ?
            JSON.parse(getQueryParam(location, QUERY_PARAMS.QUOTATION_ORDER_ITEMS)) : undefined;

        return {
            id_quotation: quotationDetails.id,
            id_invoice_address: params.invoiceAddressId,
            id_delivery_address: params.deliveryAddressId,
            id_transport_type: params.transportTypeId,
            id_payment_method: params.paymentMethodId,
            customer_reference: params.customerReference,
            customer_notices: params.customerNotices,
            is_divisible: params.isDivisible,
            items: quotationOrderItems,
        };
    };

    navigateToOrderDetail = (orderId) => {
        const {location} = this.props;
        navigateToParametrized(location, ROUTES.ORDER_DETAILS, {
            [QUERY_PARAMS.ID_ORDER]: orderId,
        });
    };

    render() {
        const {disabled, [SESSION_ATTRIBUTES.RIGHTS]: rights} = this.props;
        const orderAttributes = this.orderAttributes();
        const addressRequired = isAddressRequired(rights, orderAttributes.id_transport_type);
        const requiredNotSet = (
            orderAttributes.id_quotation == null ||
            (addressRequired && orderAttributes.id_invoice_address == null) ||
            (addressRequired && orderAttributes.id_delivery_address == null) ||
            orderAttributes.id_transport_type == null ||
            orderAttributes.id_payment_method == null
        );

        return <div>
            <ButtonFetch
                type="primary"
                disabled={requiredNotSet}
                fetchFunction={createFetchQuotationMakeOrder(orderAttributes)}
                values={{}}
                onFinish={this.navigateToOrderDetail}
                Failed={generalFailedPC(t`Nepodarilo sa vytvoriť objednávku.`)}
            >
                <Trans>Vytvoriť objednávku</Trans>
            </ButtonFetch>
        </div>;
    }

}

export default withSessionHOC(SESSION_ATTRIBUTES.RIGHTS)(locationHOC(QuotationMakeOrder));